@import url("https://fonts.googleapis.com/css?family=Crimson+Text|Work+Sans");
@import url(../../node_modules/bulma/css/bulma.css);
body {
  margin: 0;
  padding: 0;
  font-family: 'Work Sans', sans-serif !important;
  /*

  @-webkit-keyframes pulse {
    0% {
      transform: scale(.9);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 30px rgba(#5a99d4, 0);
    }
    100% {
      transform: scale(.9);
      box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
    }
  }

  */ }
  body *::selection {
    background: #ff6a59; }
  body::-webkit-scrollbar {
    width: 0; }
  body::-webkit-scrollbar-track-piece {
    background-color: transparent;
    -webkit-border-radius: 6px; }
  body a.navbar-item {
    color: white; }
  @media (max-width: 1087px) {
    body .navbar-menu {
      transition: all 0.3s ease;
      opacity: 0; }
    body .navbar-menu.is-active {
      transition: all 0.3s ease;
      position: fixed;
      bottom: 0;
      top: 52px;
      left: 0;
      right: 0;
      opacity: 1;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      body .navbar-menu.is-active .navbar-item--menu-link {
        margin-top: 17px;
        font-size: 30px;
        font-weight: 500; } }
  body .navbar.is-black .navbar-end > a.navbar-item:hover {
    background-color: #0a0a0a; }
  body a.navbar-item:hover {
    color: black; }
  body a {
    color: white; }
  body a:hover {
    color: gray; }
  body:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    opacity: 0.02;
    width: 100%;
    height: 100%;
    z-index: -1; }
  body .header-underline {
    max-width: max-content;
    border-bottom: 4px solid #209cee; }
  body .navbar {
    padding-top: 5px;
    padding-bottom: 5px; }
  body .navibar-header {
    font-size: 21px; }
    body .navibar-header:hover {
      background-color: transparent !important; }
  body .navibar-logo {
    width: 35px;
    height: 35px; }
    body .navibar-logo img {
      max-height: none; }
  body .navbar-menu {
    background-color: #0a0a0a;
    text-align: center; }
  body .intro-box {
    background-color: #0a0a0a;
    height: 100vh; }
    body .intro-box__header {
      font-family: 'Crimson Text', serif;
      font-weight: 400;
      font-size: 5rem; }
    body .intro-box__typing-cursor {
      color: white; }
  body .resume-photo {
    margin-left: 0;
    margin-right: 45px;
    shape-margin: 45px;
    shape-outside: inset(24% round 24%); }
  body .buttons-container :first-child {
    margin-right: 10px; }
  body .input-error {
    color: #E0003C;
    font-size: 0.83333rem;
    margin-top: 5px; }
  body .input-error:before {
    content: 'i';
    background-color: #E0003C;
    width: .8rem;
    height: .8rem;
    font-size: .8rem;
    display: inline-block;
    color: white;
    text-align: center;
    border-radius: 100%;
    line-height: 12px;
    margin-right: 5px; }
  @media (max-width: 1087px) {
    body .section--padding {
      padding-top: 8%;
      padding-bottom: 8%; } }
  @media (max-width: 767px) {
    body .section--padding {
      padding-top: 15%;
      padding-bottom: 15%; }
    body .resume-header {
      margin: 1.5rem auto; }
    body .resume-content {
      text-align: center; } }
  @media (max-width: 480px) {
    body .section--padding {
      padding-top: 25%;
      padding-bottom: 25%; } }
  body .pulse-button {
    transition: all 0.4s ease;
    -webkit-animation: pulse 1.5s infinite; }
  body .pulse-button:hover {
    color: white;
    transform: scale(1.2); }
  body .section-item {
    margin-bottom: 3.5rem; }
  body .resume-content__item:not(:last-child) {
    margin-bottom: 2.5rem; }
  body .resume-content__item-subheader {
    font-family: 'librebaskerville-italic', serif; }
